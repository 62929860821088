import React, { useState } from "react";
import { faq } from "../../data/houses";
import { FiMinus, FiPlus } from "react-icons/fi";

const Accordion = ({ title, label }: { title: string; label: string }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <button
      className="bg-gray-dark border border-gray-light rounded-lg px-8 py-5 flex items-center gap-4 justify-between"
      onClick={() => setIsOpen(!isOpen)}
    >
      <div className="flex flex-col gap-4 items-start">
        <h3 className="text-left text-lg lg:text-2xl font-bold">{title}</h3>
        <p
          data-open={isOpen}
          className="text lg:text-lg text-left  transition-all duration-300 data-[open=false]:hidden overflow-hidden"
        >
          {label}
        </p>
      </div>
      <div className="text-3xl self-start">
        {isOpen ? <FiMinus /> : <FiPlus />}
      </div>
    </button>
  );
};

const Faq = () => {
  return (
    <div className="lg:p-8 py-12 px-4 bg-black text-white lg:h-screen flex max-lg:flex-col justify-center gap-12 max-w-7xl mx-auto w-full items-center">
      <div className="flex flex-col gap-4 items-center">
        <h2 className="title">Perguntas Frequentes</h2>
      </div>
      <div className="flex flex-col gap-4 w-full">
        {faq.map((item) => (
          <Accordion key={item.title} title={item.title} label={item.label} />
        ))}
      </div>
    </div>
  );
};

export default Faq;
