import React from "react";
import { location } from "../../data/houses";

const Locale = () => {
  return (
    <div className="flex items-center justify-end mx-auto w-full text-white max-lg:flex-col max-lg:flex-col-reverse bg-gray-dark">
      <div className="lg:w-1/2 lg:px-8 lg:max-w-[40rem] w-full max-lg:px-4 max-lg:py-8">
        <h2 className="lg:text-5xl font-bold text-2xl pb-3 mb-3 border-b border-gray-light ">
          Localização
        </h2>
        <div className="flex flex-wrap max-lg:w-full gap-4 gap-y-8">
          {location.options.map((option) => (
            <div key={option.title} className="flex gap-2 w-[40%]">
              <div className="flex flex-col">
                <p className="text-xs">{option.title}</p>
                <div className="flex items-end gap-1">
                  <span className="text-3xl font-bold">{option.label}</span>
                  <span>{option.suffix}</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="flex-1 w-full lg:max-w-[50%]">
        <iframe
          title="Map"
          width="100%"
          className="lg:h-[600px] h-[300px]"
          src="https://maps.google.com/maps?width=100%&amp;height=600&amp;hl=en&amp;coord=52.70967533219885, -8.020019531250002&amp;q=1%20Grafton%20Street%2C%20Dublin%2C%20Ireland&amp;ie=UTF8&amp;t=&amp;z=14&amp;iwloc=B&amp;output=embed"
        ></iframe>
      </div>
    </div>
  );
};

export default Locale;
