import React, { useState } from "react";
import { houses } from "../../data/houses";
import { Link, useParams } from "react-router-dom";
import Header from "../../components/Header";
import Item from "../../components/Item";
import { numberToMonetary } from "../../utils/monetary";
import Button from "../../components/Button";
import Gallery from "../../components/Gallery";
import { GoArrowLeft } from "react-icons/go";
import Availability from "../../components/Availability";

export const House = () => {
  const params = useParams();
  const id = params?.id;

  const house = houses.find((house) => house.id === id) || {
    title: "",
    size: "",
    houseSize: "",
    price: 0,
    infos: [],
    amount: {
      total: 0,
      available: 0,
    },
    mainInfo: {
      title: "",
      label: "",
    },
    images: {
      exterior: [],
      interior: [],
      gallery: [],
    },
  };

  const [selectedImage, setSelectedImage] = useState<string | null>(
    house.images?.exterior[0] || null
  );

  return (
    <div className="relative text-white h-screen bg-black flex justify-center  flex-col">
      <div
        className="absolute inset-0 z-20 bg-cover bg-center h-screen max-lg:hidden transition-all duration-700"
        style={{
          backgroundImage: `url(${selectedImage || ""})`,
        }}
      />
      <div className="absolute inset-0 z-20 bg-black bg-opacity-50 max-lg:hidden" />
      <div className="absolute inset-0 z-30 bg-gradient-to-t from-black to-transparent lg:hidden" />
      <img
        src={selectedImage || ""}
        className="inset-0 z-20  lg:hidden min-h-[800px] object-cover"
        alt="background"
      />

      <Header />
      <div className="flex items-center justify-center flex-col max-lg:-translate-y-[100px] gap-3 z-30">
        <div className="relative z-30 max-w-7xl mx-auto w-full px-4 lg:px-8 lg:py-12 flex flex-col gap-4 max-lg:order-2">
          <Link
            className="flex items-center gap-4 max-lg:hidden"
            to="/#selection"
          >
            <div className="size-10 bg-white rounded-full flex items-center justify-center text-black">
              <GoArrowLeft size={24} />
            </div>
            <p className="leading-none">Voltar para seleção de casas</p>
          </Link>
          <div className="flex gap-4 flex-col lg:w-min lg:min-w-max">
            <div className="card gap-3 flex max-lg:flex-wrap p-4 flex-col">
              <div className="w-full flex items-center gap-4 max-lg:border-b border-gray-light max-lg:pb-4">
                <p className="text-2xl lg:text-3xl ">{house?.title}</p>
                <Availability available={house.amount.available} />
              </div>
              <div className="flex gap-2">
                <Item title="Lote, M²" label={house.size} />
                <Item title="Casa, M²" label={house.houseSize} />
                <Item title="Preço" label={numberToMonetary(house.price)} />
              </div>
              <div className="lg:hidden w-full pt-3">
                <Button className="w-full">Agendar uma chamada</Button>
              </div>
            </div>
            <div className="card flex flex-col gap-6 max-lg:mb-6 p-4">
              <div className="flex flex-col lg:flex-row gap-6">
                <div>
                  <Item
                    title={house.mainInfo.title}
                    label={house.mainInfo.label}
                    labelClassName="lg:text-3xl"
                  />
                </div>
                <div className="grid grid-cols-3 gap-4">
                  {house?.infos?.map((info) => (
                    <Item
                      key={info.title}
                      title={info.title}
                      label={info.label}
                    />
                  ))}
                </div>
              </div>
              <div className="max-lg:hidden">
                <Button>Agendar uma chamada</Button>
              </div>
            </div>
          </div>
        </div>
        <footer
          className="z-30 lg:absolute lg:bottom-0 w-full max-w-7xl mx-auto px-8 lg:py-12 max-lg:order-1
        "
        >
          <div className="flex gap-2 lg:gap-4 flex-nowrap overflow-x-auto max-lg:pb-3">
            <Gallery
              title="Exterior"
              images={house.images?.exterior || []}
              onClick={setSelectedImage}
              selectedImage={selectedImage || ""}
            />

            <Gallery
              title="Interior"
              images={house.images?.interior || []}
              onClick={setSelectedImage}
              selectedImage={selectedImage || ""}
            />
            <Gallery
              title="Galeria"
              images={house.images?.gallery || []}
              onClick={setSelectedImage}
              selectedImage={selectedImage || ""}
            />
          </div>
        </footer>
      </div>
    </div>
  );
};
