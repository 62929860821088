import React from "react";

const Availability = ({ available }: { available: number }) => {
  const isAvailable = available > 0;

  return (
    <div className="flex gap-2 items-center">
      <div
        className="size-2 rounded-full data-[available=true]:bg-green-500 data-[available=false]:bg-red-500"
        data-available={isAvailable}
      />
      <p className="text-sm">
        {isAvailable
          ? `${available} ${available > 1 ? "Disponíveis" : "Disponível"}`
          : "Indisponível"}
      </p>
    </div>
  );
};

export default Availability;
