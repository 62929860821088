import Features from "../../components/Features";
import Header from "../../components/Header";
import Hero from "../../components/Hero";
import Locale from "../../components/Locale";
import Selection from "../../components/Selection";
import Faq from "../../components/Faq";
import { houses } from "../../data/houses";

export default function Home() {
  return (
    <>
      <Header />
      <div
        className="bg-black bg-fixed bg-cover bg-center relative"
        style={{
          backgroundImage:
            "url('https://images.unsplash.com/photo-1652792997653-7e5897fb309a?q=80&w=2664&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')",
        }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-70" />
        <Hero />
        <Selection id="selection" options={houses} />
      </div>
      <Locale />
      {/* <Features /> */}
      <Faq />
    </>
  );
}
