import React from "react";

type Props = {
  title: string;
  label: string;
  className?: string;
  titleClassName?: string;
  labelClassName?: string;
};

const Item = ({
  title,
  label,
  className,
  titleClassName,
  labelClassName,
}: Props) => {
  return (
    <div className={`flex flex-col items-start min-w-20 ${className}`}>
      <h3
        className={`uppercase text-[10px] lg:text-xs opacity-70 ${titleClassName}`}
      >
        {title}
      </h3>
      <p className={`text-xs lg:text-sm ${labelClassName}`}>{label}</p>
    </div>
  );
};

export default Item;
