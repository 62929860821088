import React from "react";
import { ButtonHTMLAttributes } from "react";

type Props = ButtonHTMLAttributes<HTMLButtonElement> & {
  children: React.ReactNode;
  className?: string;
};
const Button = ({ children, className, ...props }: Props) => {
  return (
    <button
      type="button"
      className={`py-2 lg:py-3 px-4 lg:px-6 bg-white text-black rounded-3xl uppercase text-xs lg:text-sm font-medium ${className}`}
      {...props}
    >
      {children}
    </button>
  );
};

export default Button;
