export const numberToMonetary = (
  amount: number,
  fractionDigits = 2,
  currency = "BRL"
): string =>
  amount?.toLocaleString("pt-BR", {
    style: "currency",
    currency: currency,
    minimumFractionDigits: fractionDigits,
  });
