import { House } from "../types/house";

export const houses: House[] = [
  {
    title: "Casa 1",
    size: "900",
    houseSize: "100",
    price: 360000,
    amount: {
      total: 10,
      available: 5,
    },
    id: "1",
    mainInfo: {
      title: "Lançamento",
      label: "02/2024",
    },
    infos: [
      {
        title: "Quartos",
        label: "3",
      },
      {
        title: "Banheiros",
        label: "2",
      },
      {
        title: "Garagem",
        label: "2 vagas",
      },
      {
        title: "Cozinha",
        label: "8m²",
      },
      {
        title: "Área de serviço",
        label: "5m²",
      },
      {
        title: "Área de lazer",
        label: "100m²",
      },
      {
        title: "Sacada",
        label: "10m²",
      },
    ],
    images: {
      exterior: [
        "https://images.unsplash.com/photo-1652792997653-7e5897fb309a?q=80&w=2664&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        "https://images.unsplash.com/photo-1641975440216-9d8d5409e5d8?q=80&w=1632&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      ],
      interior: [
        "https://images.unsplash.com/photo-1640434037438-c3e8485687c2?q=80&w=1632&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        "https://images.unsplash.com/photo-1640912744459-0aea6e0b1bd5?q=80&w=1632&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        "https://images.unsplash.com/photo-1645237455598-e8f02d706a4e?q=80&w=1632&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      ],
      gallery: [
        "https://images.unsplash.com/photo-1507089947368-19c1da9775ae?q=80&w=1752&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        "https://images.unsplash.com/photo-1533779283484-8ad4940aa3a8?q=80&w=1740&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        "https://images.unsplash.com/photo-1554995207-c18c203602cb?q=80&w=1740&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      ],
    },
  },
  {
    title: "Casa 2",
    size: "1200",
    houseSize: "150",
    price: 450000,
    id: "2",
    amount: {
      total: 10,
      available: 0,
    },
    mainInfo: {
      title: "Data de Lançamento",
      label: "02/2024",
    },
    infos: [
      {
        title: "Quartos",
        label: "3",
      },
      {
        title: "Banheiros",
        label: "2",
      },
      {
        title: "Garagem",
        label: "2 vagas",
      },
      {
        title: "Cozinha",
        label: "8m²",
      },
      {
        title: "Área de serviço",
        label: "5m²",
      },
      {
        title: "Área de lazer",
        label: "100m²",
      },
      {
        title: "Sacada",
        label: "10m²",
      },
    ],
    images: {
      exterior: [
        "https://images.unsplash.com/photo-1652792997653-7e5897fb309a?q=80&w=2664&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        "https://images.unsplash.com/photo-1641975440216-9d8d5409e5d8?q=80&w=1632&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      ],
      interior: [
        "https://images.unsplash.com/photo-1640434037438-c3e8485687c2?q=80&w=1632&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        "https://images.unsplash.com/photo-1640912744459-0aea6e0b1bd5?q=80&w=1632&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        "https://images.unsplash.com/photo-1645237455598-e8f02d706a4e?q=80&w=1632&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      ],
      gallery: [
        "https://images.unsplash.com/photo-1507089947368-19c1da9775ae?q=80&w=1752&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        "https://images.unsplash.com/photo-1533779283484-8ad4940aa3a8?q=80&w=1740&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        "https://images.unsplash.com/photo-1554995207-c18c203602cb?q=80&w=1740&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      ],
    },
  },
  {
    title: "Casa 3",
    size: "1500",
    houseSize: "200",
    price: 540000,
    id: "3",
    amount: {
      total: 10,
      available: 5,
    },
    mainInfo: {
      title: "Data de Lançamento",
      label: "02/2024",
    },
    infos: [
      {
        title: "Quartos",
        label: "3",
      },
      {
        title: "Banheiros",
        label: "2",
      },
      {
        title: "Garagem",
        label: "2 vagas",
      },
      {
        title: "Cozinha",
        label: "8m²",
      },
      {
        title: "Área de serviço",
        label: "5m²",
      },
      {
        title: "Área de lazer",
        label: "100m²",
      },
      {
        title: "Sacada",
        label: "10m²",
      },
    ],
    images: {
      exterior: [
        "https://images.unsplash.com/photo-1652792997653-7e5897fb309a?q=80&w=2664&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        "https://images.unsplash.com/photo-1641975440216-9d8d5409e5d8?q=80&w=1632&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      ],
      interior: [
        "https://images.unsplash.com/photo-1640434037438-c3e8485687c2?q=80&w=1632&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        "https://images.unsplash.com/photo-1640912744459-0aea6e0b1bd5?q=80&w=1632&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        "https://images.unsplash.com/photo-1645237455598-e8f02d706a4e?q=80&w=1632&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      ],
      gallery: [
        "https://images.unsplash.com/photo-1507089947368-19c1da9775ae?q=80&w=1752&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        "https://images.unsplash.com/photo-1533779283484-8ad4940aa3a8?q=80&w=1740&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        "https://images.unsplash.com/photo-1554995207-c18c203602cb?q=80&w=1740&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      ],
    },
  },
  {
    title: "Casa 4",
    size: "1500",
    houseSize: "200",
    price: 540000,
    id: "3",
    amount: {
      total: 10,
      available: 5,
    },
    mainInfo: {
      title: "Data de Lançamento",
      label: "02/2024",
    },
    infos: [
      {
        title: "Quartos",
        label: "3",
      },
      {
        title: "Banheiros",
        label: "2",
      },
      {
        title: "Garagem",
        label: "2 vagas",
      },
      {
        title: "Cozinha",
        label: "8m²",
      },
      {
        title: "Área de serviço",
        label: "5m²",
      },
      {
        title: "Área de lazer",
        label: "100m²",
      },
      {
        title: "Sacada",
        label: "10m²",
      },
    ],
    images: {
      exterior: [
        "https://images.unsplash.com/photo-1652792997653-7e5897fb309a?q=80&w=2664&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        "https://images.unsplash.com/photo-1641975440216-9d8d5409e5d8?q=80&w=1632&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      ],
      interior: [
        "https://images.unsplash.com/photo-1640434037438-c3e8485687c2?q=80&w=1632&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        "https://images.unsplash.com/photo-1640912744459-0aea6e0b1bd5?q=80&w=1632&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        "https://images.unsplash.com/photo-1645237455598-e8f02d706a4e?q=80&w=1632&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      ],
      gallery: [
        "https://images.unsplash.com/photo-1507089947368-19c1da9775ae?q=80&w=1752&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        "https://images.unsplash.com/photo-1533779283484-8ad4940aa3a8?q=80&w=1740&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        "https://images.unsplash.com/photo-1554995207-c18c203602cb?q=80&w=1740&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      ],
    },
  },
  {
    title: "Casa 5",
    size: "1500",
    houseSize: "200",
    price: 540000,
    id: "3",
    amount: {
      total: 10,
      available: 5,
    },
    mainInfo: {
      title: "Data de Lançamento",
      label: "02/2024",
    },
    infos: [
      {
        title: "Quartos",
        label: "3",
      },
      {
        title: "Banheiros",
        label: "2",
      },
      {
        title: "Garagem",
        label: "2 vagas",
      },
      {
        title: "Cozinha",
        label: "8m²",
      },
      {
        title: "Área de serviço",
        label: "5m²",
      },
      {
        title: "Área de lazer",
        label: "100m²",
      },
      {
        title: "Sacada",
        label: "10m²",
      },
    ],
    images: {
      exterior: [
        "https://images.unsplash.com/photo-1652792997653-7e5897fb309a?q=80&w=2664&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        "https://images.unsplash.com/photo-1641975440216-9d8d5409e5d8?q=80&w=1632&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      ],
      interior: [
        "https://images.unsplash.com/photo-1640434037438-c3e8485687c2?q=80&w=1632&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        "https://images.unsplash.com/photo-1640912744459-0aea6e0b1bd5?q=80&w=1632&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        "https://images.unsplash.com/photo-1645237455598-e8f02d706a4e?q=80&w=1632&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      ],
      gallery: [
        "https://images.unsplash.com/photo-1507089947368-19c1da9775ae?q=80&w=1752&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        "https://images.unsplash.com/photo-1533779283484-8ad4940aa3a8?q=80&w=1740&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        "https://images.unsplash.com/photo-1554995207-c18c203602cb?q=80&w=1740&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      ],
    },
  },
  {
    title: "Casa 6",
    size: "1500",
    houseSize: "200",
    price: 540000,
    id: "3",
    amount: {
      total: 10,
      available: 5,
    },
    mainInfo: {
      title: "Data de Lançamento",
      label: "02/2024",
    },
    infos: [
      {
        title: "Quartos",
        label: "3",
      },
      {
        title: "Banheiros",
        label: "2",
      },
      {
        title: "Garagem",
        label: "2 vagas",
      },
      {
        title: "Cozinha",
        label: "8m²",
      },
      {
        title: "Área de serviço",
        label: "5m²",
      },
      {
        title: "Área de lazer",
        label: "100m²",
      },
      {
        title: "Sacada",
        label: "10m²",
      },
    ],
    images: {
      exterior: [
        "https://images.unsplash.com/photo-1652792997653-7e5897fb309a?q=80&w=2664&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        "https://images.unsplash.com/photo-1641975440216-9d8d5409e5d8?q=80&w=1632&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      ],
      interior: [
        "https://images.unsplash.com/photo-1640434037438-c3e8485687c2?q=80&w=1632&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        "https://images.unsplash.com/photo-1640912744459-0aea6e0b1bd5?q=80&w=1632&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        "https://images.unsplash.com/photo-1645237455598-e8f02d706a4e?q=80&w=1632&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      ],
      gallery: [
        "https://images.unsplash.com/photo-1507089947368-19c1da9775ae?q=80&w=1752&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        "https://images.unsplash.com/photo-1533779283484-8ad4940aa3a8?q=80&w=1740&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        "https://images.unsplash.com/photo-1554995207-c18c203602cb?q=80&w=1740&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      ],
    },
  },
];

export const location = {
  address:
    "https://maps.google.com/maps?width=100%&amp;height=600&amp;hl=en&amp;coord=52.70967533219885, -8.020019531250002&amp;q=1%20Grafton%20Street%2C%20Dublin%2C%20Ireland&amp;ie=UTF8&amp;t=&amp;z=14&amp;iwloc=B&amp;output=embed",
  options: [
    {
      title: "Padaria Dona Deôla",
      label: 3,
      suffix: "km",
      icon: "IoTrainOutline io5",
    },
    {
      title: "Open Mall The Square",
      label: 3,
      suffix: "km",
      icon: "",
    },
    {
      title: "Parque Cemucam",
      label: 2.5,
      suffix: "km",
      icon: "",
    },
    {
      title: "Shopping Granja Vianna",
      label: 5,
      suffix: "km",
      icon: "",
    },
    {
      title: "Templo Zulai",
      label: 11,
      suffix: "km",
      icon: "",
    },

    {
      title: "Rodoanel Mário Covas",
      label: 7,
      suffix: "km",
      icon: "",
    },
    {
      title: "São Paulo",
      label: 28,
      suffix: "km",
      icon: "",
    },
    {
      title: "Centro de Cotia",
      label: 6,
      suffix: "km",
      icon: "",
    },
  ],
};

export const features = [
  {
    title: "piscinas",
    label:
      "2 Piscinas com borda infinita. Coletiva e exclusiva para o salão de festas",
  },
  {
    title: "Sauna",
    label: "Sauna seca e molhada",
  },
  {
    title: "SPA",
    label: "SPA com Hidromassagens",
  },
  {
    title: "Terraço",
    label: "Terraço com vista para o parque",
  },
  {
    title: "Ginásio",
    label: "Ginásio híbrido Beauty care Salão de festas e espaço gourmet",
  },
  {
    title: "Área de espera",
    label: "Área de espera de Uber e vans",
  },
  {
    title: "Deck",
    label: "Deck circulação / cobertura",
  },
  {
    title: "Lounge",
    label: "Lounge com piscina",
  },
  {
    title: "Praça",
    label: "Praça com vista para o parque",
  },
  {
    title: "Depósito",
    label: "Depósito de garagem",
  },
];

export const faq = [
  {
    title: "Quando é o lançamento?",
    label:
      "O lançamento será no dia 02/2024. Ainda temos apartamentos disponíveis para reserva.",
  },
  {
    title: "Quais são os prazos de entrega?",
    label: "O prazo de entrega é de 36 meses. A entrega será no final de 2026.",
  },
  {
    title: "Quais os diferenciais do projeto?",
    label:
      "O projeto conta com uma ampla área de lazer, piscinas, sauna, SPA, deck, lounge, praça, depósito de garagem e área gourmet.",
  },
  {
    title: "Qual a localização do projeto?",
    label:
      "O projeto está localizado na Rua Doutor João Pessoa, 100 - Jardim São Paulo - Cotia/SP",
  },
  {
    title: "Qual a distância do metrô?",
    label:
      "A estação mais próxima é a Estação Cotia - SPTrans, que fica a aproximadamente 1,5km.",
  },
];
