import React from "react";
import Button from "../Button";
import { Link } from "react-router-dom";

type Props = {
  title?: string;
};

const Header = ({ title = "Vila de Casas" }: Props) => {
  return (
    <header className="flex justify-between items-center fixed top-0 left-0 right-0 z-20 w-full px-4 lg:px-8 py-4 max-lg:bg-gradient-to-t from-transparent to-[#252525] z-50">
      <div className="flex justify-between items-center w-full max-w-7xl mx-auto gap-4">
        <Link to="/" className="lg:text-lg font-bold text-white uppercase">
          {title}
        </Link>
        <Button>Agendar uma chamada</Button>
      </div>
    </header>
  );
};

export default Header;
