import React from "react";

type Props = {
  title: string;
  images: string[];
  onClick: (image: string) => void;
  selectedImage: string;
};

const Gallery = ({ title, images, onClick, selectedImage }: Props) => {
  return (
    <div>
      <h3 className="max-lg:hidden">{title}</h3>
      <div className="flex gap-2 lg:gap-4 lg:border-t lg:border-white lg:pt-4 mt-2">
        {images.map((image) => (
          <button key={image} onClick={() => onClick(image)}>
            <div
              className="h-[3px] data-[selected=true]:bg-white data-[selected=false]:bg-slate-300 w-10 lg:hidden"
              data-selected={selectedImage === image}
            />
            <img
              className="lg:size-20 rounded-sm object-cover border-2 border-transparent data-[selected=true]:border-white max-lg:hidden"
              data-selected={selectedImage === image}
              src={image}
              alt="title"
            />
          </button>
        ))}
      </div>
    </div>
  );
};

export default Gallery;
